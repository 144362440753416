@import "src/assets/helpers/variables";
.componentContainer {
    width: 100%;
    height: 335px;
    background: #F5F5F5;
    position: absolute;
    @media #{$media-ipad} {
        height: 199px;
    }
}

.layout{
    padding-bottom: 50px;
    position: relative;
    margin-top: 70px;
    @include display-flex;
    @media #{$media-ipad} {
        padding-bottom: 20px;
        margin-top: 32px;
    }
    .layoutContainer{
        max-width: 1054px;
        width: 100%;
        @media #{$media-ipad} {
            width: 100%;

        }
        .layoutContainerBottom{
            @media #{$media-ipad} {
                width: 100%;
                margin-top: 19px;
            }

            margin-top: 45px;
            overflow: hidden;
            &Group2{
                //@include display-flex;
                display: grid;
                grid-template-columns: calc(100% * 1/3) calc(100% * 2/3);
                grid-column-gap: 30px;
                margin-top: 30px;
                @media #{$media-ipad} {
                    margin-top: 7px;
                    grid-column-gap: 7px;
                }
            }
            &Group3{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 30px;
                @media #{$media-ipad} {
                    grid-column-gap: 7px;
                }
            }
            &Img{
                width: 100%;
                padding-top: 108%;
                &0 {
                    padding-top: 158.238636364%;
                }
                &1 {
                    padding-top: 69.4513715711%;
                }
            }


        }
        .layoutContainerHead{
            width: 100%;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

            @include display-flex;
            //height: 400px;
            background: #F5F5F5;
            @media #{$media-ipad} {
                display: unset;
                margin-bottom: 19px;
                margin-top: 32px;
                background: #F5F5F5;

            }
            .layoutContainerHeadImg{
                @media #{$media-ipad} {
                    width: 100%;
                    .headImg {
                        padding-top: 69.587628866%;
                    }
                }
                width: 50%;
                box-sizing: border-box;
                .headImg {
                    width: 100%;
                    padding-top: 69.649122807%;
                    background-size: contain;

                }

            }
            .layoutContainerHeadContent{
                padding: 40px 30px;
                width: calc(50% - 2*30px);
                text-align: end;
                @media #{$media-ipad} {
                    width: 100%;
                    padding: 0;
                    &Main {
                        padding: 26px 20px;

                        &Title {
                            font-size: 23px;
                        }
                    }
                }
                &Main{
                    background-color: #F5F5F5;
                    @include display-flex;
                    flex-direction: column;
                    align-items: flex-end;
                    &Title{
                        padding-bottom: 34px;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 27px;
                        text-align: right;
                        color: #126239;
                    }
                    &Content{
                        padding-top: 5px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        text-align: right;
                        color: #343434;
                    }
                    &Img{
                        display: flex;
                        justify-content: flex-end;
                        align-content: center;
                        width: 70%;
                        padding-top: 16px;
                        .decorImg {
                            width: 100%;
                            padding-top: 38.6666666667%;
                            background-size: contain;
                        }


                    }
                }

            }
        }
    }
}