@import "src/assets/helpers/variables";

.logo {
  padding: 14px 95px;

  .logoTitle {
    @include display-flex;
    font-weight: 700;
    font-size: 32px;
    line-height: 27px;
    color: #126239;
  }

  .logoContainer {
    @include display-flex;
    flex-direction: column;
    margin-top: 77px;
    &Img {
      @include display-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .imageContainer {
        width: 10%;
        padding: 0 32px;
        .image {
          width: 100%;
          background-size: contain;
          padding-top: 100%;
        }
      }
    }

  }

  .logoContainerLine {
    margin: 116px 0 94px;
    width: 30%;
    //height: 1px;
    border: 0.5px solid #005E4B;
  }
}


@media #{$media-ipad} {
  .logo {
    padding: 43px 20px 0;
    @include display-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logoContainer {
      width: 30%;
      margin-top: 66px;
      &Img {
        width: 100%;
        @include display-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .imageContainer {
          width: 100%;
          padding: 66px 0;
          margin: -66px 0 0;
        }
      }
    }

    .logoContainerLine {
      margin: 13px 0 41px;
      width: 200%;
    }
  }
}