@import "src/assets/helpers/variables";

.paddingContainer {
  padding: 0 95px 98px;
  @media #{$media-ipad} {
    padding: 0 20px 20px;
  }
}
.component {
  &:nth-child(even) {
    .productContainer {
      flex-direction: row-reverse;
      .description {
      }

      .titleBackground {
      }
      .productImageContainer {
        margin: 0 5% 0 -5%;
      }
      @media #{$media-ipad} {
        justify-content: space-between;

        .description {
          font-size: 12px;
          margin: 0;
        }
        .productImageContainer {
          margin: 0;
        }
        .contentWrapper {
          .titleBackground {
          }
        }
      }
    }

  }

}

.productContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: contain;

  @media #{$media-ipad} {
    justify-content: space-between;
  }
}

.contentWrapper {
  width: 33.1791907514%;
  display: flex;
  flex-direction: column;
  margin-top: 148px;
  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    margin-top: 45px;
  }

  .titleBackground {
    max-width: 864px;

    .title {
      font-size: 42px;
      font-weight: 500;
      line-height: 49px;
      text-align: left;
      color: #126239;
    }
  }
  @media #{$media-ipad} {
    width: 45%;
    margin: 30px 0 0;
    .titleBackground {
      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.41px;
        text-align: left;
      }
    }
    .description {
      margin: 15px 0 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.productImageContainer {
  width: 33.1791907514%;
  margin: 0 -5% 0 5%;
  padding: 47px 0 0;
  .productImage {
    width: 100%;
    padding-top: 119.439868204%;
    background-size: contain;
  }
  @media #{$media-ipad} {
    width: 45%;
    padding: 0;
    .productImage {
      padding-top: 142.682926829%;
    }
  }
}

.more {
  margin-top: 43px;
  width: fit-content;
  padding: 15px 34px;
  text-align: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  color: #FFFFFF;
  background-color: #126239;
  @media #{$media-ipad} {
    padding: 5px 15px;
    margin-top: 37px;
    font-size: 12px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}