@import "../../../../../../../assets/helpers/variables";

.containerMain {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  @media #{$media-ipad} {
    padding: 0;
  }

  .containerImage {
    padding: 0 20px;
    @media (max-width: 1440px) {
      padding: 0 10px;
    }
    @media #{$media-ipad} {
      padding: 0 5px;
    }
    box-sizing: border-box;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 27px;
      @media #{$media-ipad} {
        border-radius: 12px;
      }
    }
  }

  :nth-child(even) {
    padding-top: 60px;
    border-radius: 27px;
    @media #{$media-ipad} {
      padding-top: 27px;
      border-radius: 12px;
    }
  }

  :nth-child(odd) {
    padding-top: 0;
    border-radius: 27px;
    @media #{$media-ipad} {
      border-radius: 12px;
    }
  }
}
