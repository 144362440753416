@import "src/assets/helpers/variables";

.componentContainer {
  padding: 0 96px;
  padding-top: 70px;
  padding-bottom: 40px;
  @media #{$media-ipad} {
    padding: 0 20px;
    padding-top: 33px;
    padding-bottom: 14px;
  }
}
.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding-top: 117px;
  .imageContainer {
    width: calc(25% - 2*4.5px);
    height: auto;
    padding: 0 4.5px;
    .image {
      width: 100%;
      padding-top: 114.55399061%;
    }
  }
  @media #{$media-ipad} {
    padding-top: 30px;
    .imageContainer {
      width: calc(50% - 2*6px);
      padding: 6px;
      .image {
        padding-top: 106.914893617%;
      }
    }
  }
}
.description {
  padding-top: 5px;
  max-width: 1259px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;

  @media #{$media-ipad} {
    padding-top: 25px;
  }
}
