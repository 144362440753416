@import "../../../../../assets/helpers/variables";

.container {
  &Header {
    background-color: #126239;
    width: 100%;
    height: 76px;
    @include display-flex;
    align-items: center;
    @media #{$media-ipad} {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      transition: .3s;
      padding: 5px 0;
      height: auto;
      &.isShowNavigation {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
      }
    }

    .header {
      @include display-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &Logo {
        cursor: pointer;
        background-size: contain;
        width: 112px;
        height: 62px;
        @media #{$media-ipad} {
          width: 85px;
          height: 48px;
        }
      }

      &Menu {
        .container {
          &Navigation {
            @include display-flex;
            margin: 0 -24px;
            height: 76px;

            .navigation {
              &Item {
                font-size: 16px;
                font-weight: 700;
                color: #ffffff;
                cursor: pointer;
                padding-bottom: 4px;
                border-bottom: 1px solid transparent;

                &:hover, &Active {
                  border-bottom: 1px solid #FFFFFF;
                }
              }
            }
          }

        }
      }

      &Bar {
        display: none;
        @media #{$media-ipad} {
          display: block;
        }

        &Icon {
          .bar {
            height: 5px;
            border-radius: 3px;
            background-color: #ffffff;
            margin-top: 5px;
            transition: 0.3s ease;

            &:first-child {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          .topBar {
            width: 31px;
          }

          .middleBar {
            width: 31px;
          }

          .bottomBar {
            width: 31px;
          }

          &.active {
            @include display-flex;
            flex-direction: column;
            height: 30px;
            transform: rotate(180deg);
            transition: 0.3s ease;

            .bar {
              height: 3px;
              width: 23px;
              transform: rotatey(180deg) rotatex(360deg);
            }

            .middleBar {
              opacity: 0;
            }

            .topBar {
              width: 31px;
              margin-top: 16px;
              transform: rotate(-45deg);
            }

            .bottomBar {
              width: 31px;
              margin-top: -11px;
              transform: rotate(45deg);
            }
          }
        }

        input {
          display: none;
        }

        label {
          .header_bar_close {
            display: block;
            cursor: pointer;

            i {
              font-size: 20px;
            }
          }

          .header_bar_open {
            display: none;
            cursor: pointer;

            i {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  &NavLink {
    margin: 0 24px;
    @include display-flex;
    align-items: center;

    .bgDropdown {
      position: absolute;
      background-color: rgba(0, 0, 0);
      top: 76px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: .3s;
      z-index: 998;
    }

    .containerDropdown {
      height: 16vw;
      position: absolute;
      top: 76px;
      left: 0;
      right: 0;
      background-color: #ffffff;
      padding: 27px 40px;
      transition: .3s;
      opacity: 0;
      z-index: 999;
      visibility: hidden;
      @include display-flex;
      @media #{$media-ipad} {
        display: none;
      }
      @media #{$media-tablet-vertical} {
        height: 20vw;
      }
      .dropdown {
        padding: 0 12.5px;
        margin-left: 12.5px;
        width: calc(100% / 4 - 25px);
        &:hover {
          cursor: pointer;
        }
        &Item {
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
          padding: 10px;

          .image {
            width: 100%;
            padding-top: 52.8678304239%;
            background-size: contain;
          }

          .content {
            color: #000000;
            font-weight: 600;
            margin-top: 12px;
            margin-bottom: 0;
          }
        }
      }

      &BuyProduct {
        height: auto;
        .dropdown {
          width: calc(100%/7);
          border: 1px solid #F5F5F5;
          border-left: none;
          border-right: none;
          padding-top: 15px;
          padding-bottom: 15px;
          margin-left: 0;

          &:first-child {
            border-left: 1px solid #F5F5F5;
          }

          &:last-child {
            border-right: 1px solid #F5F5F5;
          }

          &Item {
            box-shadow: none;
            padding: 0;
            .image {
              width: 100%;
              padding-top: 108.695652174%;
            }
          }
        }

      }
    }

    &:hover {
      .navigationItem {
        border-bottom: 1px solid #FFFFFF !important;
      }

      .containerDropdown, .bgDropdown {
        opacity: 1;
        visibility: visible;
      }

      .bgDropdown {
        opacity: .7;
        visibility: visible;

        &:hover {
          visibility: hidden;
          opacity: 0;
          transition: .3s;

          ~ .containerDropdown {
            visibility: hidden;
            opacity: 0;
            transition: .3s;
          }

        }
      }
    }
  }
}

@media #{$media-ipad}{
  .headerStyle {
    .header {
      width: 100%;

      .header_logo {

        img {
          width: 150px;
          height: 56px;
        }
      }

      .header_menu {
        display: none;
      }

      .header_bar {
        display: block;

        input {
        }

        :checked {
          + label {
            .header_bar_close {
              display: none;
            }

            .header_bar_open {
              display: block;
            }

            div {
              display: block;
            }
          }
        }

        label {
          .header_bar_close {
            padding: 30px 50px;
            display: block;

            i {
              font-size: 30px;
            }
          }

          .header_bar_open {
            padding: 30px 50px;

            i {
              font-size: 30px;
            }
          }

          div {
            position: fixed;
            display: none;
            z-index: 200;
            background: #ffffff;
            width: 100%;
          }

        }

      }
    }
  }


}

@media #{$media-small-phone}{
  .header {
    width: 100%;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    height: 75px;
    margin-bottom: 10px;

    .header_logo {
      img {
        width: 85px;
        height: 48px;
      }
    }

    .header_menu {

      display: none;
    }

    .header_bar {
      padding: 10px 10px;
      display: block;

      i {
        margin-top: 20px;
      }
    }
  }

}

.icDropdown {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background-size: contain;
}
.shoppingItem {
  position: relative;
  &:hover {
    .containerShoppingDropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}
.containerShoppingDropdown {
  height: auto;
  width: 100vw;
  max-width: 180px;
  top: 76px;
  right: 0;
  position: absolute;
  background-color: #FFFFFF;
  transition: .3s;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  .dropdownShopping {
    width: 100%;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
    &:hover {
      cursor: pointer;
    }
    &Item {
      padding: 15px 10px;
      &:hover {
        background-color: #126239;
        .content {
          color: #FFFFFF;
          border-bottom: 1px solid #FFFFFF;
        }
      }
      .content {
        white-space: nowrap;
        width: min-content;
        margin: 0 0 0 auto;
        color: #000000;
        font-weight: 600;
        border-bottom: 2px solid transparent;
      }

    }
  }

}