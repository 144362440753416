@import "src/assets/helpers/variables";

.componentContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &Background {
    margin-top: 115px;
    width: 100%;
    height: 335px;
    background: #F5F5F5;
    position: absolute;
    @media #{$media-ipad} {
      margin-top: 53px;
      height: 199px;
    }
  }

  &Main {
    margin-top: 215px;
    width: 100%;
    max-width: 1518px;
    background: #FFFFFF;
    @include display-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media #{$media-ipad} {
      margin-top: 82px;
    }

    &Bottom {
      margin: 50px 0 105px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1054px;

      .imageContainer {
        width: calc(100% / 3 - 49px / 3);

        .componentContainerMainBottomItem {
          width: 100%;
          padding-top: 112.784090909%;
        }
      }

      @media #{$media-ipad} {
        margin: 7px 0 50px;
        .imageContainer {
          padding: 0 7px 0 0;

          &:nth-child(3n) {
            padding: 0;
          }

          width: calc(100% / 3);

          .componentContainerMainBottomItem {
            padding-top: 108%;
          }
        }
      }
    }

    &Top {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      display: flex;
      box-sizing: border-box;
      overflow: hidden;
      @media #{$media-ipad} {
        padding: 20px 20px 0;
        flex-direction: column;
        width: 100%;
      }

      .content {
        @include display-flex;
        flex-direction: column;
        width: 45%;
        padding: 50px 77px 0;
        @media #{$media-ipad} {
          width: 100%;
          padding: 0;
        }

        &Title {
          font-weight: 700;
          font-size: 32px;
          color: #126239;
          @media #{$media-ipad} {
            width: 100%;
            padding-right: unset;
            padding-top: unset;
            font-size: 23px;
          }
        }

        .descriptionContainer {
          margin-top: 34px;
          @include display-flex;
          flex-direction: column;
          .contentNote {
            margin-top: 5px;
            font-size: 16px;
            color: #333333;
            width: 100%;
            max-width: 458px;
            @media #{$media-ipad} {
            }
          }
        }

        &Img {
          display: flex;
          justify-content: flex-start;
          width: 60%;
          .decorImg {
            width: 100%;
            padding-top: 38.9548693587%;
            background-size: contain;
          }

          @media #{$media-ipad} {
            padding: 0;
            .decorImg {
              padding-top: 38.6666666667%;
            }
          }
        }
      }
    }
  }

  .mainImageContainer {
    width: 55%;

    .image {
      width: 100%;
      padding-top: 69.9629171817%;
    }

    @media #{$media-ipad} {
      width: 100%;
      padding: 0px;
      .image {
        padding-top: 37.1134020619%;
      }
    }
  }
}