@import "../../../../../../../assets/helpers/variables";

.componentContainer {
  padding: 57px 15% 14px;

}

.componentBackground {
  flex-direction: column;
  background-image: url("../../../../../../../assets/images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.titleContainer {
  @include display-flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../../../../assets/images/home-introduce.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 283px;
  height: 70px;

  .title {
    font-size: 23px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #126239;
  }
}

.contentContainer {

  width: 100%;

  .gridContainer {

    @media #{$media-ipad} {
      margin-top: 44px;
      margin-bottom: 20px;
    }
    width: 90%;
    height: auto;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 90px;
    margin-top: 70px;
    .position1 {
      grid-column: 1 / span 1 ;
      grid-row: 1
    }
    .position2 {
      grid-column: 2 / span 1 ;
      grid-row: 1
    }
    .position3 {
      grid-column: 3 / span 1 ;
      grid-row: 1
    }
    .position4 {
      grid-column: 4 / span 1 ;
      grid-row: 1
    }
    .position5 {
      grid-column: 4 / span 1 ;
      grid-row: 2
    }
    .position6 {
      grid-column: 3 / span 1 ;
      grid-row: 2
    }
    .position7 {
      grid-column: 2 / span 1 ;
      grid-row: 2
    }
    .position8 {
      grid-column: 1 / span 1 ;
      grid-row: 2
    }
  }

  .rightContainer {
    width: 10%;
    @include display-flex;
    justify-content: center;
    align-items: center;
    margin-left: -7.5%;
    .longArrow {
      width: 100%;
      padding-top: 200%;
      background-size: contain;
    }
  }
}


.contentImage {
  width: 100%;
  padding-top: 100%;
}

.grid {
  @include display-flex;
  position: relative;
  .gridContent {
    width: 80%;
    align-items: center;
    @include display-flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
    span {
      position: absolute;
      top: 100%;
      font-size: 16px;
      font-weight: 900;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #126239;
    }
  }
  .arrowContainer {
    width: 30%;
    margin: 0 -5%;
    @include display-flex;
    justify-content: center;
    align-items: center;
    .arrow {
      width: 100%;
      padding-top: 100%;
      background-size: contain;
    }
  }
}
.grid:nth-child(4),
.grid:nth-child(5n){
  .arrowContainer {
    display: none;
  }
}
.grid:nth-child(n+4) {
  .arrowContainer {
    .arrow {
      transform: scaleX(-1);
    }
  }
}