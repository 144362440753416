@import "../../../assets/helpers/variables";

.container {
  &Breadcrumb {

    border-color: #D5D5D5;
    margin: 30px auto 0;
    @media #{$media-ipad} {
      padding: 11px 0;
      margin: 20px 20px 0;
      border-color: #000000;
    }

    .content {
      border-top: 1px solid;
      border-bottom: 1px solid;
      padding: 14px 0;

      cursor: pointer;
      font-size: 14px;
      color: #999999;
      margin: 0;

      .breadcrumb {
        cursor: text;
        font-weight: 500;
        color: $active-color;
      }
    }
  }
}