@import "src/assets/helpers/variables";

.component {
  display: flex;
  flex-direction: column;
  padding: 37px 95px 62px;
  justify-content: center;
  @media #{$media-ipad} {
    padding: 26px 20px 0;
  }
}

.descriptionSizing {
  width: 100%;

  .description {
    margin: auto;
    width: 100%;
    max-width: 1048px;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    @media #{$media-ipad} {
      font-size: 14px;
    }
  }
}

.imagesContainer {
  width: 100%;
  display: flex;
  padding-top: 70px;

  .imageSizing {
    width: calc((100% / 3) - 73px);
    padding: 0 36.5px;

    .image {
      width: 100%;
      padding-top: 73.2954545455%;
    }
  }

  @media #{$media-ipad} {
    padding-top: 34px;
    flex-wrap: wrap;
    .imageSizing {
      width: calc(100% / 2 - 7px);

      &:nth-child(odd) {
        padding: 7px 7px 7px 0;
      }

      &:nth-child(even) {
        padding: 7px 0 7px 7px;
      }

      .image {
        padding-top: 94.6524064171%;
      }
    }
  }

}

