@import "src/assets/helpers/variables";

.partContainer {
  padding-top: 17px;
  margin-bottom: 50px;
  @include display-flex;

  .partContainerContent {
    @include display-flex;
    width: 90%;

    .partContainerContentLine {
      width: 1px;
      border-left: 2px solid #126239;
      border-right: 2px solid #126239;
      transform: rotate(0deg);
    }

    .partContainerContentForm {
      width: 48%;
      padding: 10px 89px;
    }

    .partContainerContentInfo {
      width: 48%;
      padding: 10px 89px;
    }
  }

}

//responsive
@media #{$media-ipad} {
  .partContainer {
    @include display-flex;
    flex-direction: column;
    padding: 20px 20px 0;

    .partContainerContent {
      display: unset;
      width: 100%;

      .partContainerContentLine {
        border-left: unset;
        border-right: unset;

        height: 2px;
        border-top: 2px solid #126239;
        border-bottom: 2px solid #126239;
        width: 100%;
        margin: 0 auto;
        transform: rotate(0deg);
      }

      .partContainerContentForm {
        width: 100%;
        padding: 68px 0 0;
      }

      .partContainerContentInfo {
        width: 100%;
        padding: 0;
      }
    }

  }
}