
.certification{
  padding-top: 100px;
  .certificationMain{
    background-image:url("../../../../../assets/images/about-zili-certification.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    width: 70%;
    height: 400px;

  }
  .certificationTitle{
    div{
      margin: 0 auto;
    }

  }
}