@import "src/assets/helpers/variables";
.partnerForm{
  width: 100%;
  form{
    padding: 10px 0;
      .partnerFormBottom{
        width: 100%;
        .partnerFormBottomContact{

          width: 100%;
          &Content{
            font-size: 18px;
            color: #000000;
            text-align: center;
            span{
              white-space: nowrap;
              text-decoration: underline solid #EB001B;
              color: #EB001B;
              font-weight: 600;
            }
          }
        }
        .partnerFormBottomButton{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          &Submit{
            margin-top: 20px;
            padding: 10px 30px;
            background: #126239;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            border: none;
          }
          &Submit:hover {
            cursor: pointer;
          }
        }
      }
      }

    .partnerFormBody{

      width: 100%;
      box-sizing: border-box;
      .body{

        width: 100%;
      }
        .partnerFormBody1{
            position: relative;
            &Input{
              padding: 14px 20px;
              width: 100%;
              border-radius: 5px;
              border: 1px solid #000000;
              box-sizing: border-box;
            }
            &Error{
              position: absolute;
              top:27px;
              color:#f50909;
              font-size: 12px;
            }
        }
        .partnerFormBody2{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &Container{
              width: 48%;
              position: relative;
              &Input{
                padding: 14px 20px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid #000000;
                color: #999999;
                box-sizing: border-box;
              }
              &Error{
                padding: 2px 3px;
                position: absolute;
                top:25px;
                color:#f50909;
                font-size: 12px;
              }
            }

        }
        .partnerFormBody3{
          margin-top: 20px;
          position: relative;
          &Text{
            font-family: Roboto;
            padding: 14px 20px;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #000000;
            font-size: 16px;
            box-sizing: border-box;
          }
          &Error{
            position: absolute;
            top:52px;
            color:#f50909;
            font-size: 12px;
          }
        }
      .partnerFormBodyRadio{

        .partnerFormBodyRadioGroup{
          padding: 20px 0px;
          .partnerFormBodyRadioGroup2{
            margin-left: 33px;
          }
          display: flex;
          &Label{
            display: flex;
            justify-content: space-between;
            span{

              font-weight: 400;
              font-size: 16px;
              color: #000000;

            }
          }
          &Label > input[type="radio"] {
            display: none;
          }
          &Label > input[type="radio"] + *::before {
            content: "";
            display: inline-block;
            vertical-align: bottom;
            width: 1rem;
            height: 1rem;
            margin-right: 0.3rem;
            border-radius: 50%;
            border-style: solid;
            border-width: 0.1rem;
            border-color:  #000000;
          }
          &Label > input[type="radio"]:checked + * {
          }
          &Label > input[type="radio"]:checked + *::before {
            background: radial-gradient(#126239 0%, #126239 100%, transparent 50%, transparent);
            border-color: #126239;
          }



        }
      }
    }
    .partnerFormHead{
      &Title{
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      &Content{
        font-weight: 400;
        font-size: 14px;
        color: #555555;
      }
    }
}
inputAction[type=radio]:checked {
  appearance: none;
  background-color: #1a8b16;
  margin: 25px auto;
  font: inherit;
  color: green;
  width: 1.15em;
  height: 1.05em;
  border: 0.2em solid #F1F1F1;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
//responsive
@media #{$media-ipad} {
  .partnerForm{
    form{
      .partnerFormBottom{
        width: 100%;
        .partnerFormBottomContact{
          width: 100%;
          &Content{
            font-weight: 400;
            font-size: 18px;
            color: #000000;
            span{
              text-decoration: underline solid red;
              color: red;
              font-weight: 600;
            }
          }
        }
        .partnerFormBottomButton{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          &Submit{
            margin-top: 12px;
            padding: 10px 30px;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            border: none;
          }
        }
      }
    }
    .partnerFormBody{
      display:flex;
      flex-direction: column;
      justify-content: center;
      .body{
        width: 100%;

      }
      .partnerFormBody1{

        &Input{
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #000000;
        }
        &Error{
          top:40px;
        }
      }
      .partnerFormBody2{
        margin-top: 0px;
        display: unset;
        justify-content: unset;
        align-items: unset;
        &Container{
          width: 100%;
          margin-top: 25px;
          &Input{
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #000000;
          }
          &Error{
            position: absolute;
            top:25px;
            color:#f50909;
            font-size: 12px;
          }
        }

      }
      .partnerFormBody3{
        margin-top: 20px;
        position: relative;

        &Text{
          width: 100%;
          height: 250px;
          border: 1px solid #000000;
          box-sizing: border-box;
        }
        &Error{
          position: absolute;
          top:60px;
          color:#f50909;
          font-size: 12px;
        }
      }
    }
    .partnerFormHead{
      padding: 0px 0px;
      &Title{
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      &Note{
        font-weight: 400;
        font-size: 14px;
        color: #555555;
      }
    }

  }



}