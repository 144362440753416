@import "../../../../../../assets/helpers/variables";

.content {
  padding: 75px 95px 0;
  @include display-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &Title {
    @include display-flex;

    h2 {
      all: unset;
      font-weight: 700;
      font-size: 32px;
      line-height: 27px;
      color: #126239;
    }

    @media #{$media-ipad} {
      padding: 45px 20px 0;
    }
  }

  &Content {
    @include display-flex;
    width: 80%;
    //max-width: 1045px;
    flex-direction: column;
    padding: 100px 0;
    @media #{$media-ipad} {
    }
    &Robusta {
      display: table;
      flex-wrap: wrap;
      &Item {
        display: table-cell;
        flex-direction: column;
        width: calc(100% / 3);
        padding: 0 8%;
        position: relative;
      }
    }

    &Arabica {
      display: table;
      flex-wrap: wrap;
      margin-top: 100px;
      &Item {
        display: table-cell;
        flex-direction: column;
        width: calc(100% / 3);
        padding: 0 8%;
        position: relative;
      }
    }
  }
}