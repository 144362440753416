@import "src/assets/helpers/variables";

.content{
  padding: 32px 95px 0;
  &Container{
    background: #126239;
    padding: 37px 10%;
    @include display-flex;
    flex-direction: column;
    &Main{
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #FFFFFF;
      line-height: 153.19%;
    }
  }
}

@media #{$media-ipad} {
  .content{
    padding: 20px 20px 0;
    &Container{
      background: #126239;
      padding: 31px 31px;
      &Main{
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #FFFFFF;
        line-height: 153.19%;
      }
    }
  }
}