@import 'src/assets/helpers/variables';

.container {
  &Slider {
    position: relative;

    .contentTitle {
      width: 362px;
      height: 90px;
      background-image: url("../../../../../../../assets/images/home-introduce.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 27px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        color: #19463D;
        padding: 20px 95px;
      }
    }

    .prevEl {
      width: 50px;
      height: 50px;
      font-size: 30px;
      position: absolute;
      top: 450px;
      left: 70px;
      z-index: 200;
      display: none;
      cursor: pointer;
      color: #19463D;
    }

    .nextEl {
      width: 50px;
      height: 50px;
      font-size: 30px;
      color: #19463D;
      position: absolute;
      top: 450px;
      left: 900px;
      z-index: 200;
      cursor: pointer;
    }
  }

  &Content {
    padding: 20px 0 0 35px;
    @media #{$media-ipad} {
      padding: 0;
    }

    .contentReview {
      @include display-flex;
      flex-direction: column;
      @media #{$media-ipad} {
      }

      &Description {
        font-size: 16px;
        color: #555555;
        @media #{$media-ipad} {
          margin-bottom: 0;
        }
      }

      .title {
        font-weight: 700;
        font-size: 20px;
        color: #000000;
        margin-bottom: 0;
      }

      .contentReviewActionMap {
        .contentReviewMap {
          position: relative;
          width: 100%;

          .img {
            width: 100%;
            padding-top: 53.9400665927%;
            background-size: contain;
            background-position: left;
            @media #{$media-ipad} {
              padding-top: 73.1308411215%;
              background-position: center;
            }
          }

          h2 {
            position: absolute;
            top: 110px;
            left: 560px;
            z-index: 200;
            font-weight: 700;
            font-size: 13px;
            color: #FFFFFF;
          }
        }

        .contentReviewAction {
          div {
            margin-bottom: 22px;

            input {
              background: #999999;
            }

            span {
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 23px;
              color: #19463D;
            }
          }
        }
      }

      .btnContainer {
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @media #{$media-ipad} {
          padding: 0;
        }
      }
    }
  }
}

@media #{$media-ipad} {

  .land {

    position: relative;

    .prevEl {
      position: absolute;
      width: 50px;
      height: 50px;
      font-size: 30px;
      top: 300px;
      left: 20px;
      z-index: 200;
      display: block;
      color: #19463D;
    }

    .nextEl {
      position: absolute;
      width: 50px;
      height: 50px;
      font-size: 30px;
      color: #161717;
      top: 300px;
      left: 640px;
      z-index: 200;
    }

    .swiper {
      width: 100%;

      .content {
        margin: 10px;
        width: 100%;

        .contentReview {
          width: 100%;
          margin: 0 auto;
          position: relative;

          .contentReviewLand {
            position: absolute;
            top: 400px;
            font-weight: 700;
            font-size: 20px;
            color: #000000;
          }

          .contentReviewDescription {
            position: absolute;


            font-size: 16px;

            top: 450px;
          }

          a {
            position: absolute;
            top: 570px;
            left: 260px;
          }

          .contentReviewActionMap {
            position: relative;

            .contentReviewMap {

              position: relative;

              box-sizing: border-box;
              overflow: hidden;
              background-image: url("../../../../../../../assets/images/home-map.svg");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;

              .contentReviewMapBackGround {
                width: 100%;
                height: auto;
              }

              .contentReviewMapVt {
                top: 303px;
                left: 79px;
                width: 95px;
                height: 70px;
              }

              .contentReviewMapVtDl {

                top: 220px;
                left: 152px;

                width: 66px;
                height: 60px;

              }

              .contentReviewMapVtLd {

                top: 260px;
                left: 148px;
                width: 63px;
                height: 62px;

              }
            }

          }
        }


      }
    }

  }

}

@media #{$media-small-phone} {

  .land {

    position: relative;

    .prevEl {
      position: absolute;
      width: 50px;
      height: 50px;
      font-size: 30px;
      top: 300px;
      left: 20px;
      z-index: 200;
      display: block;
      color: #19463D;
    }


    .swiper {
      width: 100%;

      .content {
        margin: 10px;
        width: 100%;

        .contentReview {
          width: 100%;
          margin: 0 auto;
          position: relative;

          .contentReviewLand {
            position: absolute;
            top: 310px;
            font-weight: 700;
            font-size: 20px;
            color: #000000;
          }

          .contentReviewDescription {
            position: absolute;
            font-weight: 400;
            font-size: 16px;
            color: #555555;
            top: 350px;
          }

          .contentReviewActionMap {
            position: relative;

            .contentReviewMap {

              position: relative;

              box-sizing: border-box;
              overflow: hidden;

              h2 {
                position: absolute;
                top: 110px;
                left: 560px;
                font-size: 13px;
              }

              .contentReviewMapBackGround {

              }

              .contentReviewMapVt {


                top: 303px;
                left: 79px;
                width: 95px;
                height: 70px;
              }

              .contentReviewMapVtDl {

                top: 220px;
                left: 152px;

                width: 66px;
                height: 60px;

              }

              .contentReviewMapVtLd {

                top: 260px;
                left: 148px;
                width: 63px;
                height: 62px;

              }
            }

          }
        }


      }
    }

  }

}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #333333;
  margin: 25px auto;
  font: inherit;
  color: #FA6E17;
  width: 1.15em;
  height: 1.05em;
  border: 0.2em solid #F1F1F1;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1.1em 1.1em #FA6E17;
  background-color: #FA6E17;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {

}

.btnLoadMore {
  height: 40px;
  border: 1px solid #005E4B;
  background: #ffffff;
  font-weight: 400;
  font-size: 16px;
  color: #19463D;
  text-align: center;
  padding: 0 18px;
  cursor: pointer;
  width: max-content;
  @media #{$media-ipad} {
    margin: 20px auto 0;
  }
}

.btnLoadMore:hover {
  cursor: pointer;
  background-color: #126239;
  color: #FFFFFF;
}

.slider {
  &Next, &Prev {
    width: 17px;
    height: 33px;
    position: absolute;
    top: 50%;
    z-index: 9;
    cursor: pointer;
    @media #{$media-ipad} {
      width: 10px;
      height: 20px;
      top: 140px;
    }
  }

  &Next {
    right: 0;
    @media #{$media-ipad} {
      right: 0%;
    }
  }

  &Prev {
    left: 0;
  }
}

.containerInfoCoffee {
  @include display-flex;
  width: 100%;
  padding-top: 30px;
  @media #{$media-ipad} {
    display: block;
  }

  .titleFarm {
    font-weight: 700;
    font-size: 23px;
    color: #19463D;
    width: 20%;
    @media #{$media-ipad} {
      font-size: 20px;
      width: 100%;
      margin-bottom: 23px;
    }
  }

  .infoCoffee {
    width: 80%;
    @include display-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media #{$media-ipad} {
      width: 100%;
      padding: 0 20px 28px;
    }

    &Item {
      width: 25%;
      @media #{$media-ipad} {
        width: 50%;
        margin-bottom: 20px;
      }

      .title {
        margin: 0 0 10px 0;
        font-weight: 700;
        font-size: 20px;
        color: $active-color;
        @media #{$media-ipad} {
          margin-bottom: 7px;
        }
      }

      .content {
        margin: 0 0 10px;
        font-size: 16px;
        color: #555555;
      }
    }
  }
}