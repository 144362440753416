@import "src/assets/helpers/variables";

.containerValueCoffee{
  width: 100%;
  background-color: #F5F5F5;
  .valueCoffeeTitle{
    @include display-flex;
    justify-content: center;
    align-content: center;
  }
  .valueCoffee{
    width: 90%;
    background-position: right;
    background-size: contain;
    padding: 5% 5% 10%;
    @media #{$media-ipad} {
      width: 95%;
      padding: 2% ;
      background-position: center;
      background-size: cover;
      //display: unset;
    }
    .valueCoffeeContainerLeft{
      margin-top: -10vw;
      @media #{$media-ipad} {
        margin: 0;
      }
      .containerItem{
        width: 100%;
        margin-top: 10vw;
        @include display-flex;
        position: relative;
        @media #{$media-ipad} {
          display: unset;
        }
        &:nth-child(even) {
            .imageItem{
              right: 8%;
          }
        }
        @media #{$media-ipad} {
          margin: 22px 0 50px auto;
          width: calc(100% - 137px);
          margin-right: 17px;
        }
        .imageItem{
          position: absolute;
          right: 0;
          @media #{$media-ipad} {
            position: unset;
            width: 64%;
            padding: 18%;
          }
          width: 18%;
          .img {
            width: 100%;
            padding-top: 100%;
          }
        }

        .containerContent{
          width: 80%;
          @include display-flex;
          @media #{$media-ipad} {
            width: 100%;
          }
          .containerNumber{
            width: 20%;
            @media #{$media-ipad} {
              width:25%;
            }
            .backgroundValue{
              margin-top: -50px;
              width: 250px;
              height: 250px;
              @include display-flex;
              justify-content: center;
              align-items: center;
              @media #{$media-ipad} {
                justify-content: center;
                width: 100%;
                height: 137px;
                margin-top: -20px;
              }
              .number{
                font-weight: bold;
                font-size: 90px;
                color: transparent;
                -webkit-text-stroke: 2px $active-color;
                @media #{$media-ipad} {
                  font-size: 40px;
                  -webkit-text-stroke: 1px $active-color;
                }
              }
            }
           }
          .containerDescription{
              width: 80%;
            padding-right: 12%;
            @media #{$media-ipad} {
              padding-right: 3%;
            }
            @media only screen and (max-width : 1280px) {
              margin-left: 5%;
            }
            @media only screen and (max-width : 1024px) {
              margin-left: 10%;
            }
            .title{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 700;
              font-size: 23px;
              line-height: 27px;
              color: #19463D;
            }
            .description{
              font-size: 16px;
              line-height: 22px;
              color: #555555;
            }
          }
        }
      }
    }
  }
}
