@import "src/assets/helpers/variables";

.componentContainer {
  margin-top: 20px;
  @media #{$media-ipad} {
    display: none;
  }
}
.bannerImage {
  width: 100%;
  height: auto;
  padding-top: 20.9826589595%;
  @media #{$media-ipad} {
    padding-top: 35.0515463918%;
    display: none;
  }
}