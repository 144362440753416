@import "../../../../../assets/helpers/variables";

.container {

  &Info{
    @include display-flex;
    justify-content: flex-end;
    @media #{$media-ipad} {
      justify-content: unset;
      display: unset;
      width: 100%;
      background: #19463D;
    }
    .containerInfoBtn{
      padding: 50px 0px;
      @include display-flex;
      justify-content: center;
      button{
        display: none;
        padding: 7px 19px;
        background: #ffffff;
        border: 1px solid #19463D;
        color:#19463D;
        @media #{$media-ipad} {
          display: block;
        }
      }
    }
  }
  &Planting {
    @include display-flex;
    @media #{$media-ipad} {
     display: block;
    }
    &Img {

      width: 38%;
      @include display-flex;
      padding: 0 0 0 5%;

      @media #{$media-ipad} {
        max-width: 261px;
        width: 100%;
        margin: 0 auto 68px;
        padding: unset;
      }

      .Image {
        width: 100%;
        background-size: contain;
        padding-top: 130.804597701%;

      }
    }

    .landContent {

      padding: 0 0 0 10%;
      width: 62%;
      @media #{$media-ipad} {
        padding: 0;
        width: 100%;
      }
    }
  }
}

.contentTitle {
  //@include display-flex;

  margin: 0 auto 21px;
  background-image: url("../../../../../assets/images/home-introduce.png");
  @include display-flex;
  align-items: center;
  width: max-content;
  background-size: contain;
  .title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 23px;
    color: #19463D;
    padding: 20px 95px;
    @media #{$media-ipad} {
      padding: 17px 41px;
    }
  }
}

@media #{$media-ipad}{
  .land {
    display: unset;

    .landImg {
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      padding: 41px 83px;
    }

    .landContent {
      width: 100%;
    }
  }

}


