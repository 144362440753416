.manufacturing{

  background: #F1F1F1;
  height: auto;
  .manufacturingTitle{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .manufacturingContainer{
    margin-top: 63px;
    padding: 100px 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    a{
      text-decoration: none;
      position: absolute;
      left: 95%;
      i{
        font-size: 40px;
        color: #005E4B;
      }

    }

    .manufacturingItem1{

    }
    .manufacturingItem2{

    }
    .manufacturingItem3{

    }
    .manufacturingItem4{

    }
    .manufacturingItem5{

    }
  }
}
.manufacturing div:nth-child(odd) {
  padding-top: 0px;
}

.manufacturing div:nth-child(even) {
  padding-top: 60px;
}
