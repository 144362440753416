@import "../../../assets/helpers/variables";
html {
  scroll-behavior: smooth;
}
.container {
  max-width: 1730px;
  padding: 0 40px;
  margin: 0 auto;
  @media #{$media-ipad} {
    padding: 0 20px;
  }
}

.d-flex {
  @include display-flex;
}

.justify {
  &-content {
    &-space-between {
      justify-content: space-between;
    }
  }
}

.justify {
  &-content {
    &-center {
      justify-content: center;
    }
  }
}

.align {
  &-items {
    &-center {
      align-items: center;
    }
  }
}

.nav {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  &-link {
    margin-left: 60px;
    color: #000;
    font-size: 20px;
    font-weight: 700;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    &:active, &:focus {
      color: $main-color;
      text-decoration: none;
    }
  }

  &__mobile {
    display: none;
  }
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.on {
  &-desktop {
    display: block;
    @media #{$media-ipad} {
      display: none;
    }
  }

  &-phone {
    display: none;
    @media #{$media-ipad} {
      display: block;
    }
  }
}