@import "src/assets/helpers/variables";
.contentTitle{
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/home-introduce.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75px;
  width: 100%;
  min-width: 283px;
  span{
    white-space: nowrap;
    color: #126239;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    align-items: center;
    padding: 20px 0;
    //@media #{$media-ipad} {
    //  font-size: calc(21px + (23 - 21) * ((100vw - 300px) / (1200 - 300)));
    //}
  }
  @media #{$media-ipad} {
    width: 277px;
    height: 60px;

    span {
      font-size: 23px;
    }
  }
}