@import "../../../assets/helpers/variables";

.slider {
  &Image {
    .swiper {
      &-slide {
        &:nth-child(even) {
          padding-top: 84px;
          @media #{$media-ipad} {
            padding-top: 37px;
          }
        }

        @media #{$media-ipad} {
          padding-bottom: 20px;
        }
      }

      &-pagination {
        bottom: 0 !important;

        &-bullet {
          width: 15px;
          height: 5px;
          background: #DFDFDF;
          border-radius: 15px;
          opacity: 1;
          transition: .3s;
          margin: 0 1.5px !important;

          input {
            margin: 100px;
          }

          &-active {
            background: #19463D;
            width: 31px;
          }
        }
      }
    }
  }

  &Introduce {
    height: 60vh;
    min-height: 700px;
    @media #{$media-tablet-horizontal} {
      margin-top: 10vw;
    }
    @media #{$media-ipad} {
      max-width: calc(100vw - 40px);
      height: auto;
      padding-bottom: 20px !important;
      min-height: auto;
    }

    .swiper-pagination {
      display: none;
      @media #{$media-ipad} {
        bottom: 0 !important;
        display: block;
        &-bullet {
          width: 15px;
          height: 5px;
          background: #DFDFDF;
          border-radius: 15px;
          opacity: 1;
          transition: .3s;
          margin: 0 1.5px !important;

          input {
            margin: 100px;
          }

          &-active {
            background: #19463D;
            width: 31px;
          }
        }
      }
    }


    .swiper {
      &-wrapper {
        max-height: 50vh;
        @media #{$media-ipad} {
          max-height: unset;
          .swiper-slide {
            height: auto;
          }
        }
      }

      &-pagination {
        bottom: 0 !important;

        &-bullet {
          background: #DFDFDF;
          border-radius: 15px;
          width: 15px;
          height: 3px;
          transition: .3s;
          opacity: 1;

          &-active {
            background: #19463D;
            width: 31px;
          }
        }
      }
    }
  }

  &Planting {
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        @media #{$media-ipad} {
          width: fit-content;
        }
    }
    .swiper {
      &-pagination {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        position: relative;
        @media #{$media-ipad} {
          background-color: #ffffff;
          top: 0;
        }

        .pagination {
          &Planting {

            &.swiper {
              &-pagination {
                &-bullet {
                  margin-right: 100px;
                  width: 23px;
                  height: 23px;
                  background-color: #F1F1F1;
                  position: relative;
                  margin-bottom: 23px;
                  margin-left: 0;

                  @media #{$media-ipad} {
                    margin-bottom: 19px;
                  }

                  &:last-child {
                    margin-bottom: 0
                  }

                  @include display-flex;
                  justify-content: center;
                  align-items: center;
                  opacity: 1;

                  .radio-color {
                    display: block;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background-color: #999999;
                  }

                  .name {
                    font-weight: 700;
                    font-size: 20px;
                    color: #19463D;
                    position: absolute;
                    left: 35px;
                  }

                  &-active {
                    .radio-color {
                      background-color: #FA6E17;
                    }
                  }
                }

              }
            }
          }
        }
      }

      &-wrapper {
        @media #{$media-ipad} {
        }
      }
    }
  }
}

.sliderPlanting {
  width: 75%;
  display: flex;
  flex-direction: column;
  @media #{$media-ipad} {
    width: 100%;
  }
  .swiper-pagination {
    order: 1;
    .swiper-pagination-bullet {
      top: 10px;
    }
  }
  .swiper-wrapper {
    order: 2;
  }
}

.slideBtn {
  &.swiper {
    &-button {
      &-disabled {
        border: 1px solid #19463D4D !important;
        cursor: not-allowed !important;
        div {
          opacity: 0.5;
        }
      }
    }
  }
}

.swiperStyle {
  .swiper-wrapper {
    .swiper-slide {
      margin-top: 80px;
      margin-bottom: 0px;
      margin-left: 3px;
    }
  }

  .swiper-pagination {
    position: relative;

    .swiper-pagination-bullet-active {
      input {
        background: #FA6E17;
      }
    }

    .swiper-pagination-bullet {
      width: 100px;
      background-color: unset;
      height: 50px;
      border-radius: unset;
      display: flex;
      margin-top: 20px;
      font-family: 'Kanit';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height */
      opacity: 1;
      color: #19463D;

      span {
        text-align: center;
        align-items: center;
        padding: 18px 20px;
      }
    }

    .Arabica {
      position: absolute;
      top: 0px;
      left: 50px;
    }

    .Robusta {
      position: absolute;
      top: 50px;
      left: 50px;
    }
  }
}

// styles banner
.mySwiperNBanner {
  .swiper-wrapper {
    .swiper-slide {
      .styleImagesSlider {
        width: 100%;
        height: 250px;
      }
    }
  }
}

//
.mySwiperBanner {
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      .slide-introduce_introduceLeft {
        .slide-introduce_introduceLeftContentImage {
          @include display-flex;

          div {
            img {
              width: 20px;
            }
          }
        }
      }

      .styleImagesSlider {
        width: 100%;
        @media #{$media-ipad} {
          max-height: 225px;
        }
      }

      img {

        max-height: 700px;
      }

    }
  }

}

@media #{$media-ipad} {
  .banner_banner__lifFy {
    padding: 20px 40px;
    .swiper {
      .swiper-button-prev {
        display: none;
      }
      .swiper-button-next {
        display: none;
      }
      .swiper-wrapper {
        .swiper-slide {
          .slide-introduce_introduceLeft {
            .slide-introduce_introduceLeftContentImage {
              display: flex;
              div {
                img {
                  width: 20px;
                }
              }
            }
          }

          .styleImagesSlider {
            width: 95%;
            height: 400px;
          }

          img {
            max-height: 700px;
          }

        }
      }
    }
  }
}

//home-factory slider
.factorySwiper {
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;

      div {
        width: 100%;
        max-height: 790px;
        box-sizing: border-box;

        img {
          width: 100%;
          max-height: 790px;
        }
      }
    }
  }
}

@media #{$media-small-phone} {
  .swiperStyle {
    width: 100%;

    .swiper-wrapper {
      .swiper-slide {
        margin: 60px;
      }
    }

    .swiper-pagination {
      //background-color: #
      position: relative;

      .swiper-pagination-bullet {


        width: 100px;

        height: 50px;

        display: flex;
        margin-top: 20px;
        opacity: 1;

        span {
          text-align: center;
          align-items: center;
          padding: 18px 20px;
        }
      }

      .swiper-pagination-bullet-active {
        input {

          background: #FA6E17;
        }
      }


      .Arabica {
        position: absolute;
        top: 50px;
        left: 25px;
      }

      .Robusta {
        position: absolute;
        top: 120px;
        left: 25px;
      }
    }
  }
}

.ProductSwiper {
  .swiper {
    &-pagination {
      &-bullet {
        &-active {
          background-color: #126239;
        }
      }
    }
  }
}

.productListSwiper {
  &.swiper {
    width: 70%;
    padding-left: 52px;
  }
}

//swiper store
.mySwiperStore {
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }

  .swiper-wrapper {
    .swiper-slide {

    }
  }
}

//Swiper coffeebean phone
.coffeeBeanSlider {
  .swiper-wrapper {
    padding-bottom: 100px;

    .swiper-slide {
      height: auto;
      position: relative;
    }
  }
}

.mySwiperCoffeeBean {
  &.swiper {
    margin: 0 7.60416666667%
  }

  .swiper-wrapper {
    .swiper-slide {
      .image {
        width: 100%;
        padding-top: 136.67953668%;
      }
    }
  }

  @media #{$media-ipad} {
    &.swiper {
      //margin: 0 56px;
    }
    .swiper-wrapper {
      .swiper-slide {
        .image {
          width: 100%;
          padding-top: 136.666666667%;
        }
      }
    }
  }
}
.productionContentSlider {
  &.swiper {
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
      }
    }
    .swiper-pagination {
      &-bullet {
        background-color: #FFFFFF;
      }
    }
  }
}
.productionGallerySlider {
  &.swiper {
    .swiper-wrapper {
      .swiper-slide {
        height: auto;
      }
    }
  }
}