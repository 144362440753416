$media-tablet-horizontal: "only screen and (max-width : 1160px)";
$media-tablet-vertical: "only screen and (max-width : 900px)";
$media-ipad: "only screen and (max-width : 768px)";
$media-small-phone: "only screen and (max-width : 320px)";
$media-small-fold: "only screen and (max-width : 281px)";
//zili coffee
$active-color: #19463D;
$sub-color: #005E4B;
$main-color: #333333;
$main-bg: #fff;

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-box;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
@mixin display-flex-important {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -moz-box !important;
  display: -moz-flex !important;
  display: -webkit-flex !important;
  display: flex !important;
}