@import "src/assets/helpers/variables";

.farm{
  width: 100%;
  @media #{$media-ipad} {
    display: unset;
  }
  .farmAddress{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .farmAddressItem{
      padding: 0 20px;
      margin-left: -20px;
      @include display-flex;
      flex-direction: column;
      width: calc(25% - 40px);
      @media #{$media-ipad} {
        width: calc(50% - 40px);
      }
      .farmAddressItemTitle{
        font-weight: 700;
        font-size: 20px;
        color: #19463D;
      }
      .farmAddressItemContent{
        font-size: 16px;
        color: #555555;
      }
    }
  }
}
