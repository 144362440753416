@import "../../../../../../assets/helpers/variables";

.content {
  padding: 45px 20px 0;

  &Title {
    @include display-flex;
    h2 {
      all: unset;
      font-weight: 700;
      font-size: 23px;
      line-height: 27px;
      color: #126239;
    }
  }

  &Content {
    @include display-flex;
    flex-direction: column;
    margin-top: 54px;
    &Robusta {
      width: 100%;
      padding: 0 20px 100px;
      &Item {
        //display: table-cell;
        flex-direction: column;
      }
    }

    &Arabica {
      width: 100%;
      padding: 0 20px;
      &Item {
        //display: table-cell;
        flex-direction: column;
      }
    }
  }

}