@import "../helpers/variables";

@font-face {
  font-family: 'SVN-Poppins';
  src: url("../font/SVN-Poppins/SVN-Poppins-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: 'play-ball';
  src: url("../font/Playball/Playball-Regular.ttf");
}
html, body {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $main-color;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.poppins-font {
  font-family: SVN-Poppins, sans-serif;
}