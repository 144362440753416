@import "src/assets/helpers/variables";

.componentContainer {
  margin-bottom: 100px;
  .titleContainer{
    @include display-flex;
    margin-bottom: 40px;
  }
  .addressContainerMain{
    width: 80%;
    margin:  0  auto;
    height: 372px;
    display: flex;
    justify-content: space-between;
    @media #{$media-ipad} {
      display: unset;
      width: 100%;
    }
    &Left{
      width: 35%;
      @media #{$media-ipad} {

        width: 100%;
      }
      .Active{
        background: #F5F5F5;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      }
      &Action{
        padding: 15px;
        color: #000000;
        margin-bottom: 20px;
        .title{
          font-weight: 700;
          font-size: 16px;

          -webkit-text-decoration:  #000000 2px solid underline;
          text-decoration:  #000000  solid underline;
          -webkit-text-underline-position: under;
          text-underline-position: under;

        }
        .address{
          margin-top: 15px;
          margin-bottom: 20px;
          font-size: 16px;
          text-align: start;
        }
        .groupBottom{
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          @media #{$media-ipad} {
            font-size: 12px;
          }
          &Open{
            a{
              color: red;
            }
          }
          &View{
            font-weight: 400;
            a{
              color:#000000;
            }
          }
        }
      }
      div:hover {
        cursor: pointer;
      }
    }
    &Right{
      @media #{$media-ipad} {
        width: 100%;
      }
      width: 60%;
      box-sizing: border-box;
      overflow: hidden;
      height: auto;
    }
  }
  @media #{$media-ipad} {
    padding: 0 20px;
  }
}
.addressContainer1 {
  padding-top: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media #{$media-ipad} {
    padding-top: 6px;
  }
}
.addressContainer2 {
  padding-top: 45px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoContainer {
  max-width: 877px;
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  margin-bottom: 9px;
  .icon {
    max-width: 19.38px;
    height: 24.59px;
    padding: 15px;
    img {
      width: 100%;
      height: auto;
    }
    @media #{$media-ipad} {
    }
  }
  .wrapper1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 13px;
    margin-left: -13px;
    @media #{$media-ipad} {
    }
  }
}
.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.content {
  padding-top: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.contentHighlighted {
  padding-top: 20px;
  color: #EB001B;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.wrapper2 {
  max-width: 877px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.map {
  max-width: 877px;
  width: 100%;
  height: 406px;
  @media #{$media-ipad} {
    padding-top: 13px;
  }
}
