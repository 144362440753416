@import "src/assets/helpers/variables";

.containerBanner {
  @media #{$media-ipad} {
    padding: 15px 20px;
  }
}
.videoContainer {
  width: 100%;
  padding-top: 32.708333333333%;
  position: relative;
  video {
    position: absolute;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  @media #{$media-ipad} {
    padding-top: 57.4742268041%;
  }
  .icPlay {
    position: absolute;
    top: 43%;
    left: 48%;
    right: 0;
    bottom: 0;
    z-index: 200;
    width: 55px;
    height: 55px;
    @media #{$media-ipad} {
      top: 40%;
      left: 42%;
    }
  }
  .hide {
    display: none;
  }
}



.imagesSlider {
  width: 100%;
  padding-top: 32.708333333333%;
  @media #{$media-ipad} {
    padding-top: 57.4742268041%;
  }
}