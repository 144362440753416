@import "src/assets/helpers/variables";

.describe{
  background: #126239;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &Content{
    @include display-flex;
    flex-direction: column;
    padding: 53px 5% ;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
  }

  .contentTitle{
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../../../assets/images/home-introduce.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 282px;
    height: 75px;
    span{
      color: #FFFFFF;
      font-weight: 700;
      font-size: 32px;
      text-align: center;
      align-items: center;
      padding: 20px 0;
    }
  }
}
@media #{$media-ipad} {
  .describe{
    padding: 0;
    margin: 0;
    &Title{
      @include display-flex;
    }
    &Content{
      margin-top: 32px;
      padding: 0 24px 24px;
      line-height: 29px;
    }
  }
}