@import "src/assets/helpers/variables";
.imageContainer {
  padding-top: 32px;

  .image{
    padding-top: 11.387283237%;
  }
}



@media #{$media-ipad} {
  .imageContainer {
    display: none;
  }
};