@import "src/assets/helpers/variables";

.componentContainer {
  display: flex;
  align-items: center;
  padding: 0 96px;
  padding-top: 42px;
  @media #{$media-ipad} {
    padding: 0 20px;
    padding-top: 20px;
  }
}
.bannerImage {
  height: auto;
  width: 100%;
  padding-top: 21.6310005784%;
  background-size: cover;
  position: relative;
  .bannerImageEffect {
    width: 100%;
    padding-top: 21.6310005784%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
  }
  @media #{$media-ipad} {
    padding-top: 37.1134020619%;
    .bannerImageEffect {
      padding-top: 37.1134020619%;
    }
  }
}
.contentContainer {
  margin-left: -96px;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 500;
  .title {
    font-size: 23px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF
  }
  .description {
    padding-top: 10px;
    max-width: 728px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF
  }
  @media #{$media-ipad} {
    margin-left: -20px;
    .title {
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0em;
    }
    .description {
      padding-top: 8px;
      max-width: 324px;
      font-size: 12px;
      line-height: 1.2;
    }
  }
}
