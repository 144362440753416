@import "src/assets/helpers/variables";

.tabWholesale {
  .tabShopDiscount {
    .wrapper {
      @include display-flex;
      flex-direction: column;

      .title {
        color: #126239;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
      }

      .contentWrapper {
        padding: 12px 0 40px;
        @include display-flex;
        flex-direction: column;
        .content {
          padding-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          @media #{$media-ipad} {
            color: #000000;

          }
          p {
            all: unset;
            color: #FA6E17;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            @media #{$media-ipad} {

            }
          }
        }
        @media #{$media-ipad} {
          padding: 12px 0 35px;
        }
      }
    }

    .decorImageContainer {
      width: 64.5214521452%;
      margin-top: -15px;
      .decorImage {
        width: 100%;
        padding-top: 38.8746803069%;
      }
      @media #{$media-ipad} {
        width: 100%;
        margin-top: -19px;
        .decorImage {
          padding-top: 38.9175257732%;
        }
      }
    }
  }
}