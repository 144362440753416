@import "../../../../../../../assets/helpers/variables";

.introduceHeader {
  position: relative;
  padding: 50px 30px;
  @media #{$media-ipad} {
    border-left: none;
    padding: 0;
  }

  .introduceSlider {
    display: flex;
    width: 100%;
    margin-top: 40px;
    @media #{$media-ipad} {
      margin-top: 0;
      padding:unset;
    }
    .introduceSliderItem {
      width: 100%;
      position: relative;
      border-left:1px solid rgba(25, 70, 61, 0.3);
      @media #{$media-ipad} {
        border-left:none;
      }

      .slide {
        &PrevBtn, &NextBtn {
          position: absolute;
          width: 28px;
          height: 28px;
          border: 1px solid $active-color;
          border-radius: 50%;
          cursor: pointer;
          z-index: 100;
          background: #ffffff;
          left: -15px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media #{$media-ipad} {
            display: none;
          }
        }
        &PrevBtn:hover, &NextBtn:hover {
          cursor: pointer;
          background-color: #126239;
          color: #FFFFFF;
        }
        &PrevBtn {
          bottom: calc(50% - 120px);
          z-index: 3;
          .slidePrevBtnIcons {
            width: 60%;
            height: 60%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;}

        }

        &NextBtn {
          top: calc(50% - 123px);
          z-index: 3;

          .slideNextBtnIcons {
            width: 60%;
            height: 60%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }
    }
  }

  .introduceHeaderContent {
    position: absolute;
    width: 40%;
    z-index: 2;
    padding-top: 4%;
    @media #{$media-ipad} {
      position: static;
      width: auto;
      margin-bottom: 40px;
      background-image: none !important;
      padding-top: unset;
    }

    .introduceLeftContentHeader {
      @include display-flex;
      align-items: center;
      justify-content: flex-start;
      padding:0%  4%;
      //margin-top: 10%;
      @media #{$media-ipad} {
        margin-bottom: 14px;
        width: auto;
        justify-content: center;
      }
    }

    .introduceLeftContentTitle {
      padding-left: 15%;
      color: $sub-color;

      p {
        font-family: Play-ball;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        margin: 0;
      }

      @media #{$media-ipad} {
        padding-left: 0;
      }
    }
  }

  .introduceSlider {
    width: 100%;

    @media #{$media-ipad} {
      border-left: none;
    }

    .introduceLeft {
      @include display-flex;
      width: 100%;
      position: relative;
      align-items: center;
      @media #{$media-ipad} {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
      }

      .introduceLeftContentImage {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media #{$media-ipad} {
          width: 100%;
        }
      }


      .introduceLeftContent {
        width: 40%;
        @media #{$media-ipad} {
          width: 100%;
        }

        .introduceLeftContent_about {
          padding: 5vw 20px 0 15%;
          position: relative;
          @media only screen and (max-width : 1600px) {
            padding: 12vw 20px 0 15%;
          }
          @media only screen and (max-width : 1440px) {
            padding: 18vw 20px 0 15%;
          }
          @media only screen and (max-width : 1280px) {
            padding: 22vw 20px 0 15%;
          }
          .introduceLeftContent_aboutTitle {
            color: $active-color;
            font-style: normal;
            font-weight: 700;
            font-size: 23px;
            margin: 0 0 14px;

            text-transform: uppercase;
            @media #{$media-ipad} {
              font-size: 20px;
            }
          }

          .introduceLeftContentAboutContent {
            max-width: 556px;
            color: #555555;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 100px;
            @media #{$media-ipad} {
              font-size: 14px;
              max-width: none;
              margin-bottom: 43px;
            }
          }

          @media #{$media-ipad} {
            padding: 0;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

.container {
  &Index {
    width: 60px;
    height: 60px;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    font-size: 20px;
    border: 1px solid $active-color;
    color: $active-color;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    @media #{$media-ipad} {
      display: none;
    }
  }
}

.btn {
  background: #FFFFFF;
  color: $active-color;
  border: 1px solid $active-color;
  font-size: 16px;
  padding: 7px 18px;
  cursor: pointer;
  margin-top: 66px;

  @media #{$media-ipad} {
    margin: 27px auto 0;
  }
}
.btn:hover {
  cursor: pointer;
  background-color: #126239;
  color: #FFFFFF;
}