@import '../../../../../assets/helpers/variables';

.container {
  &Slider {
    @include display-flex;
    justify-content: space-between;
    width: 100%;
    @media #{$media-ipad} {
      flex-direction: column;
      background-image: none !important;
    }
  }
}

.slider {
  &Item {
    margin: 0 -10px;
    padding: 0 10px;
    width: 100%;
    @media #{$media-ipad} {
      width: 100%;
    }
  }
}