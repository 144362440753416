@import "src/assets/helpers/variables";

.component {
  padding: 91px 95px 0;
  @media #{$media-ipad} {
    padding: 20px 20px 0;
  }
}

.container {
  display: flex;
  justify-content: center;
  @media #{$media-ipad} {
    flex-direction: column;
  }
}

.productImageContainer {
  width: 100%;
  .imageSizing {
    padding: 0 22.7167630058%;
  }
  .productImage {
    width: 100%;
    padding-top: 65.2557319224%;
  }
  @media #{$media-ipad} {
    width: 100%;
    .imageSizing {
      width: 100%;
      padding: 0;
    }
    .productImage {
      padding-top: 65.4639175258%;
    }
  }
}

.contentWrapper {
  width: 35.7291666667%;
  padding-top: 139px;

  .wrapperContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: left;
      color: #126239;
      white-space: nowrap;
    }
    .content {
      padding-top: 25px;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      color: #126239;
    }
    .descriptionContainer {
      padding-top: 52px;
      .description {
        max-width: 343px;
        width: 100%;
        border-bottom: 1px solid #D0D0D0;
        padding: 10px 50px 10px 0;
        display: flex;
        justify-content: flex-start;

        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: left;
          white-space: nowrap;
        }
        strong {
          padding-right: 120px;
        }
      }
      .description:last-child {
        border-bottom: none;
      }
    }
    .decorImage {
      width: 100%;
      padding-top: 38.8888888889%;
    }
  }

  @media #{$media-ipad} {
    padding-top: 53px;
    width: 100%;
    .wrapperContainer {
      width: 100%
    }
  }
}

.productListContainer {
  display: flex;
  padding: 140px 0 91px;
  .subContent {
    width: 30%;
    border-right : 1px solid #126239;
    padding-right: 52px;
    span {
      max-width: 483px;
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .productList {
    display: flex;
    .productContainer {
      display: flex;
      flex-direction: column;
      &:hover {
        cursor: pointer;
      }
      .image {
        width: 100%;
        padding-top: 130.222222222%;
      }
      .productTitle {
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #19463D;
        @media #{$media-ipad} {
          font-size: 14px;
        }
      }
      .productDescription {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #555555;
        @media #{$media-ipad} {
          font-size: 14px;
        }
      }
      span {
        padding-top: 16px;
      }
    }
  }
  @media #{$media-ipad} {
    flex-direction: column;
    padding: 63px 0 34px;
    .subContent {
      width: 100%;
      border-right: none;
      border-bottom : 1px solid #126239;
      padding: 0 0 25px 0;
      span {
        font-size: 14px;
      }
    }
    .productList {
      width: 100%;
      padding: 25px 0 34px;
      .productContainer {
        padding: 0;
        .image {
          padding-top: 130.081300813%;
        }
      }
    }

  }
}

