@import "src/assets/helpers/variables";

.partnerTitle{
   width: 100%;
  @include display-flex;
  padding-top: 40px;
  padding-bottom: 80px;
  h2{
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color: #126239;
  }
 }

@media #{$media-ipad} {
  .partnerTitle{
    width: 100%;
    @include display-flex;
    padding: 33px 0 0;
    h2{
      all:unset;
      font-weight: 500;
      font-size: 23px;
      text-align: center;
      width: 90%;
      color: #126239;
    }
  }


}