@import "../../../../../assets/helpers/variables";
.factory{
  margin-top: 22px;
  object-fit: cover;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  .factoryIcon{
    position: absolute;
    top: 350px;
    left: 900px;
    z-index: 200;
    color: #f50909;
  }
}
.container {
  &Media {
    position: relative;
    width: 100%;
    .img{
      width: 100%;
      padding-top: 41.14583333333%;
      @media #{$media-ipad} {
        padding-top: 75.2336448598%;
      }
    }
    .icPlay {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 65px;
      height: 65px;
      cursor: pointer;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media #{$media-ipad} {
        width: 40px;
        height: 40px;
      }
    }
  }
  &Video {
    position: relative;
    width: 100%;
    padding-top: 41.14583333333%;
    @media #{$media-ipad} {
      padding-top: 75.2336448598%;
    }
    .video {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      object-fit: cover;
    }
  }
}