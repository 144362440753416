
@import "src/assets/helpers/variables";

.partnerInfo{
  font-family: Roboto, sans-serif;
  .partnerInfoHead{
    margin-bottom: 35px;
    padding: 10px 0;
    @include display-flex;

    &Item{
      .active{
        color: #126239;
        border-bottom: 2px solid  #126239;
      }
    }

    span{
      white-space: nowrap;
      font-weight: 700;
      font-size: calc(15px + (18 - 15) * ((100vw - 280px) / (1920 - 280)));
      line-height: 5px;
      color: #999999;
      cursor: pointer;
      padding-bottom: 5px;

    }
    .marginTab {
      margin-left: 21px;
    }
  }
  .partnerInfoContent{
    display: flex;
    flex-direction: column;

  }
}
@media #{$media-ipad} {
  .partnerInfo{
    padding: 64px 0 35px;

    .partnerInfoHead{
      justify-content: space-between;
      max-width: 100%;
      width: 100%;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      &Item{
        width: 50%;
        span{

          width: 100%;
        }
      }
      .marginTab {
        margin-left: unset;
      }
    }
    .partnerInfoContent{
    }

  }
}
@media #{$media-small-fold} {

  .partnerInfo{
    padding: 64px 0 35px;
    .partnerInfoHead{
      justify-content: space-between;
      max-width: 100%;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      span{
        white-space: nowrap;
        font-weight: 700;

        line-height: 5px;
        color: #999999;
        cursor: pointer;
        padding-bottom: 5px;
        width: 48%;
        font-size:12px;
      }
    }
    .partnerInfoContent{
    }

  }
}