@import "src/assets/helpers/variables";
.line{
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    height: auto;
    width: 50%;
    border: 1px solid #F5F5F5;
  }
}