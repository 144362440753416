@import "src/assets/helpers/variables";

.componentContainer {
  padding: 0 96px;
  padding-top: 20px;
  @media #{$media-ipad} {
    padding: 0 20px;
    padding-top: 19px;
  }
}

.topBannerImage {
  height: auto;
  width: 100%;
  padding-top: 20.9826589595%;
  background-size: cover;
  @media #{$media-ipad} {
    padding-top: 35.0515463918%;
    background-size: cover;
  }
}
.videoContainer {
  width: 100%;
  padding-top: 20.9826589595%;
  position: relative;
  .topBannerVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media #{$media-ipad} {
    padding-top: 35.0515463918%;
  }
}
