@import "../../../../../assets/helpers/variables";

.footerContainer {
  padding: 55px 95px 0;
  @include display-flex;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  @media #{$media-ipad} {
    width: calc(100% - 2 * 20px);
    flex-direction: column;
    padding: 20px 20px 44px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .col {
    &1 {
      width: 25%;

      .wrapper {
        width: 100%;

        .logoContainer {
          width: 21.6981132075%;

          .logo {
            width: 100%;
            padding-top: 56.5217391304%;
          }
        }

        .contentContainer {
          padding: 15px 0 26px;

          .content {
            word-break: break-all;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.05em;
            text-align: left;
          }
          @media #{$media-ipad} {
            padding: 13px 0 31px;
            .content {
              line-height: 15px;
            }
          }
        }

        .copyrightContainerDesktop {
          padding-top: 22px;
          border-top: 1px solid #000000;
          @media #{$media-ipad} {
            display: none;
          }

          .copyright {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
          }
        }

      }

      @media #{$media-ipad} {
        width: 100%;
        .wrapper {
          padding: 0;
        }
      }
    }

    &2 {
      width: 25%;

      .wrapper {
        @include display-flex;
        flex-direction: column;
        align-items: flex-start;
        width: 55%;
        padding-left: 45%;

        a {
          word-break: break-word;
          padding-top: 15px;
          text-decoration: none;
          color: #000000;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      @media #{$media-ipad} {
        width: 100%;
        .wrapper {
          padding: 0;
          width: 100%;
        }
      }
    }

    &3 {
      width: 25%;

      .wrapper {
        width: 80%;
        @include display-flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20%;

        .contactItemContainer {
          @include display-flex;
          align-items: center;
          width: 100%;
          padding-top: 20px;

          color:#000000;
          .iconContainer {
            width: 24px;
            height: 24px;
            .img {
              width: 100%;
              height: 100%;
              //margin-top: 5px;
            }
          }

          .contentContainer {
            width: calc(100% - 39px);
            padding-left: 12px;

            .content {
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              cursor: pointer;
            }
            .contentPhoneAndEmail {
              word-break: break-all;
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              cursor: pointer;
            }
          }
        }
      }

      @media #{$media-ipad} {
        width: 100%;
        .wrapper {
          padding: 33px 0 0;
          width: 100%;
        }
      }
    }

    &4 {
      width: 25%;

      .wrapper {
        width: 85%;
        padding-left: 15%;

        .socialContainer {
          padding-top: 15px;
          width: 100%;
        }

        .containerFooterIcons {
          width: calc(100% - 3 * 16px);
          @include display-flex;
          justify-content: flex-start;
          padding-top: 20px;

          .iconContainer {
            width: 26px;
            height: 26px;
            padding-right: 16px;

            .icon {
              width: 100%;
              padding-top: 100%;
              background-size: contain;
            }
          }
        }
      }

      @media #{$media-ipad} {
        width: 100%;
        .wrapper {
          padding: 45px 0 43px;
          width: 100%;
        }
      }
    }
  }

  .copyrightContainerPhone {
    display: none;
    @media #{$media-ipad} {
      @include display-flex;
      width: 60%;
      padding-top: 24px;
      border-top: 1px solid #000000;
      .copyright {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
    }
  }
}
.govLogo {
  height: 46px;
  width: 120px;
  min-width: 120px;
  cursor: pointer;
  margin-top: 20px;
}