@import "../../../../../../../assets/helpers/variables";

.container {
  @include display-flex;
  align-items: center;
  border-bottom: 1px solid #D0D0D0;
  padding: 19px 0;
  overflow-x: hidden;
  position: relative;
  &Navigation {
    position: fixed;
    background-color: #ffffff;
    top: -100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 99;
    transition: ease-in-out top 0.3s;
    padding: 25px 20px;
    overflow-y: auto;
    &.show {
      top: 57px;
      height: auto;

    }

    &Item {
      margin-bottom: 19px;
    }
  }

  &Title {
    @include display-flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 19px;
  }

  &Manufacturing, &Image {
    @include display-flex;
    align-items: center;
  }

  &Manufacturing {
    width: 100%;
  }

  &Image {
    min-width: calc(100% / 3);

    .image {
      &Product {
        width: 100%;
        padding: 0 20px;
        margin-left: -20px;
        .image {
          width: 100%;
          padding-top: 140.579710145%;
        }

        .imageConnect {
          margin: 0 6px;
          padding-top: 140.579710145%;
          background-size: contain;
        }
      }
      &Manufacturing {
        width: 50%;

        .image {
          width: 100%;
          padding-top: 140.579710145%;
        }

        .imageConnect {
          margin: 0 6px;
          padding-top: 140.579710145%;
          background-size: contain;
        }
      }
    }

    &:last-child {
      .image {
        &Manufacturing {
          .imageConnect {
            display: none;
          }
        }
      }
    }
  }

  .icNext {
    position: absolute;
    right: 0;
    width: 24px;
    height: 24px;
    background-size: contain;
  }

  &Store {
    @include display-flex;
    justify-content: space-between;
    margin: 0 -12px;
    .store {
      width: 50%;
      padding: 0 12px;
      .imageStore {
        width: 100%;
        padding-top: 60.1063829787%;
        border-radius: 3px;
      }
    }
  }
  &Shopping {
    @include display-flex;
    flex-direction: column;
    .content {
      padding: 15px 10px;
    }
  }
}

.navigation {
  &Item {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }
}

.subTitle {
  color: #555555;
  font-size: 16px;
  margin-top: 10px;
}
.shoppingItem {
  position: relative;
  &:hover {
    .containerShoppingDropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}

.containerShopping {
  width: 100%;
  margin: -19px 0;
  .dropdownShopping {
    padding: 19px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &:last-child {
      border-bottom: unset;
    }
    .content {
      color: #000;
      font-size: 16px;
      font-weight: 400;
    }
  }
}