@import "src/assets/helpers/variables";
.componentContainer {
  padding: 4px 5.625% 96px;
  @media #{$media-ipad} {
    padding: 0 20px 80px;
    margin-top: 20px;
  }
}
.titleContainer {
  @include display-flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../../assets/images/home-introduce.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 283px;
  height: 70px;
  margin-bottom: 66px;
  .title {
    font-size: 23px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
    color: #126239;
  }
  @media #{$media-ipad} {
    margin-bottom: 33px;
  }
}
.fluidContainer {
  @include display-flex;
  @media #{$media-ipad} {
    flex-direction: column;
    .leftContainer {
      width: 100%;
      flex-direction: column;
      padding: 0;
      margin: 0;
      .contentContainer {
        margin-bottom: 6px;
        span {
          margin-bottom: 8px;
        }
      }
        .gallery {
        width: 100%;
        flex-wrap: wrap;
        .imageContainer {
          padding: 4px;
          width: calc(50% - 4px*2);
        }
      }
    }
    .rightContainer {
      width: 100%;
      margin: 24px 0 0;
    }
  }
}
.leftContainer {
  width: 40%;
  padding: 0 10%;
  margin-left: -5%;
  .contentContainer {
    @include display-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 45px;
    span {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      @media #{$media-ipad} {
        font-size: 16px;
      }
    }
  }

}
.rightContainer {
  width: 45%;
  margin-left: -5%;
  .videoContainer {
    position: relative;
    width: 100%;
    padding-top: 51.6046213094%;
    .icPlay {
      position: absolute;
      top: 43%;
      left: 48%;
      right: 0;
      bottom: 0;
      z-index: 200;
      width: 55px;
      height: 55px;
      @media #{$media-ipad} {
        top: 40%;
        left: 42%;
      }
    }
    .hide {
      display: none;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .image {
    width: 100%;
    padding-top: 51.6046213094%;
  }
  .navBtn{
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 45%;
    .next{
      position: absolute;
      right: -5%;
      width: 14px;
      height: 28px;
      background-size: contain;
      z-index: 200;
      @media #{$media-ipad} {
        display: none;
      }
    }
    .prev{
      position: absolute;
      left: -5%;
      width: 14px;
      height: 28px;
      background-size: contain;
      z-index: 200;
      @media #{$media-ipad} {
        display: none;
      }
    }
    .prev:hover, .next:hover {
      cursor: pointer;
    }
  }
}