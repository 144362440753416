@import "src/assets/helpers/variables";
.tabShop{
  .tabShopDiscount {
    .wrapper {
      @include display-flex;
      flex-direction: column;
      .title {
        color: #126239;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
      }
      .contentWrapper {
        padding: 12px 0 40px;
        @include display-flex;
        flex-direction: column;
        .content {
          padding-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          @media #{$media-ipad} {

          }
          p {
            all: unset;
            color: #FA6E17;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: right;
            @media #{$media-ipad} {

            }
          }
        }
      }
    }
    .decorImageContainer {
      width: 20.8955223881%;
      margin-top: -15px;
      padding-left: 19.4719471947%;

      .decorImage {
        width: 100%;
        padding-top: 157.142857143%;
      }
      @media #{$media-ipad} {
        width: 30%;
        @include display-flex;
        padding-left: 35%;
        justify-content: center;
        .decorImage {
          padding-top: 157.142857143%;
        }
      }
    }
  }
}