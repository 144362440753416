@import "../../../../../../assets/helpers/variables";
.componentContainer {
  width: 100%;
  height: auto;
  padding-bottom: 146px;
  @media #{$media-ipad} {
    padding-top: 14px;
    padding-bottom: 20px;
  }
}
.title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #{$active-color};
  @media #{$media-ipad} {
    font-style: normal;
  }
}
.description {
  padding-top: 11px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  @media #{$media-ipad} {
    font-style: normal;
    line-height: 1.6;
  }
}
.imageContainer {
  width: 50%;
  padding: 0 29.5px;
  .leftImage {
    width: 100%;
    height: auto;
    padding-top: 56.890459364%;
    background-size: cover;
  }
  @media #{$media-ipad} {
    width: calc(100% - 40px);
    padding: 0 20px;
    padding-bottom: 11px;
    .leftImage {
      padding-top: 51.2886597938%;
      background-size: cover;
    }
  }
}
.wrapper {
  width: 50%;
  padding: 0 29.5px;
  .contentWrapper {
    padding: 59px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mainDescription {
    display: flex;
    flex-direction: column;
  }
  @media #{$media-ipad} {
    width: calc(100% - 40px);
    padding: 0 20px;
    .contentWrapper {
      padding: 0 0;
      padding-top: 32px;
    }
  }
}
.content {
  width: 45%;
  display: flex;
  flex-direction: column;
  @media #{$media-ipad} {
    width: 100%;
    padding-bottom: 24px;
  }
}