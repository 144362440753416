@import "../../../../../../../../assets/helpers/variables";

.contentItemsImage {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .image {
    width: 100%;
    //height: 100px;
    padding-top: 100%;
  }

  @media #{$media-ipad} {
  }
}

.contentItemsImageLine {
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #126239;
  border-top: 2px solid #126239;
  margin-top: 20px;
}

.contentItemsImageInfo {
  @include display-flex;
  flex-direction: column;

  &Title {
    font-weight: 900;
    font-size: 18px;
    line-height: 153.19%;
    color: #FA6E17;
    margin-bottom: 8px;
  }

  &Des {
    font-weight: 600;
    font-size: 16px;
    line-height: 153.19%;
    color: #126239;
    margin-bottom: 3px;
  }
}

.contentItemsImageContact {
  position: absolute;
  top: 100%;
  margin-top: 10px;
  &Action {
    white-space: nowrap;
    padding: 7px 29px;
    border: 1px solid #19463D;
    color: #19463D;
    font-size: 16px;
    line-height: 21px;
    background: none;
  }
  &Action:focus, &Action:active {
    cursor: pointer;
    background-color: #126239;
    color: #FFFFFF;
  }
}
