@import "../../../../../../../assets/helpers/variables";

.componentContainer {
  padding: 0 15% 20px;
}

.componentBackground {
  padding-bottom: 87px;
  flex-direction: column;
  background-image: url("../../../../../../../assets/images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.titleContainer {
  @include display-flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../../../../assets/images/home-introduce.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 283px;
  height: 70px;

  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #126239;
  }
}

.contentContainer {
  width: 100%;

  .gridContainer {
    width: 90%;
    height: auto;
    display: grid;
    gap: 0;
    grid-template-columns: repeat(4, 1fr);

    .position1 {
      grid-column: 1 / span 1;
      grid-row: 1
    }

    .position2 {
      grid-column: 2 / span 1;
      grid-row: 1
    }

    .position3 {
      grid-column: 3 / span 1;
      grid-row: 1
    }

    .position4 {
      grid-column: 4 / span 1;
      grid-row: 1
    }

    .position5 {
      grid-column: 4 / span 1;
      grid-row: 2
    }

    .position6 {
      grid-column: 3 / span 1;
      grid-row: 2
    }

    .position7 {
      grid-column: 2 / span 1;
      grid-row: 2
    }

    .position8 {
      grid-column: 1 / span 1;
      grid-row: 2
    }
  }

  .rightContainer {
    width: 10%;
    @include display-flex;
    justify-content: center;
    align-items: center;
    margin-left: -7.5%;

    .longArrow {
      width: 100%;
      padding-top: 200%;
      background-size: contain;
    }
  }
}


.contentImage {
  width: 100%;
  padding-top: 100%;
}

.grid {
  @include display-flex;

  .gridContent {
    width: 80%;
    align-items: center;
    @include display-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    span {
      position: absolute;
      top: 100%;
      font-size: 16px;
      font-weight: 900;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #126239;
      white-space: nowrap;
    }
  }

  .arrowContainer {
    width: 30%;
    margin: 0 -5%;
    @include display-flex;
    justify-content: center;
    align-items: center;

    .arrow {
      width: 100%;
      padding-top: 100%;
      background-size: contain;
    }
  }
}

.grid:nth-child(4),
.grid:nth-child(5) {
  .arrowContainer {
    display: none;
  }
}

.grid:nth-child(n+4) {
  .arrowContainer {
    .arrow {
      transform: scaleX(-1);
    }
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .moreBtn {
    height: 40px;
    width: max-content;
    border: 1px solid #005E4B;
    background: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #19463D;
    text-align: center;
    padding: 0 18px;
    cursor: pointer;
  }
  .moreBtn:hover {
    cursor: pointer;
    background-color: #126239;
    color: #FFFFFF;
  }
}