@import "src/assets/helpers/variables";

.componentContainer {
  padding: 0 96px;
  padding-top: 80px;
  padding-bottom: 542px;
  @media #{$media-ipad} {
    padding: 0 20px;
    padding-top: 57px;
    padding-bottom: 94px;
  }
}
.container {
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.description {
  max-width: 1259px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}
.imagesContainer {
  padding-top: 75px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  .imageContainer {
    width: calc(25% - 2*4.5px);
    height: auto;
    padding: 0 4.5px;
    .image {
      width: 100%;
      padding-top: 114.55399061%;
    }
  }
  @media #{$media-ipad} {
    padding-top: 36px;
    display: flex;
    flex-wrap: wrap;
    .imageContainer {
      width: calc(50% - 2*6px);
      padding: 6px;
      .image {
        padding-top: 106.914893617%;
      }
    }
  }
}
