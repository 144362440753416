@import "../../../../../../assets/helpers/variables";
.componentContainer {
  padding: 40px 0 137px;
  @media #{$media-ipad} {
    width: calc(100% - 40px);
    padding: 20px 20px 137px;
  }
}
.imageContainer {
  width: 50%;
  padding: 0 53px;
  .leftImage {
    width: 100%;
    height: auto;
    padding-top: 97.0358814353%;
    background-size: contain;
  }
  @media #{$media-ipad} {
    width: 100%;
    padding: 63px 0;
    margin-top: -63px;
    .leftImage {
      padding-top: 192.783505155%;
      background-size: contain;
    }
  }
}
.wrapper {
  width: 50%;
  padding: 0 53px;
  .contentImage {
    width: 100%;
    height: auto;
    padding-bottom: 28px;
    padding-top: 77.0206022187%;
    background-size: contain;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 7px 0;
  }
  @media #{$media-ipad} {
    width: 100%;
    padding: 0;
    .contentImage {
      padding-top: 77.0186012678%;
      background-size: contain;
      margin-bottom: 41.54px;
    }
    .description {
      padding-bottom: 7px;
    }
  }
}