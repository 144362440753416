@import "src/assets/helpers/variables";

.container {
  display: flex;
  justify-content: center;
  @media #{$media-ipad} {
    display: block;
  }
}

.mainTitle {
  padding: 22px 0;
  font-size: 23px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #{$active-color};
}

.titleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url("../../../assets/images/home-introduce.png");
  background-position: center;
  background-repeat: no-repeat;
  @media #{$media-ipad} {
    padding: 25px 0;
  }
}