.menuFactoring{
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  img{
    max-width: 211px;
    height: 296px;
  }
  p{
    width: 108px;
    height: auto;
    text-align: center;
    padding: 6px 5px;
    border-radius: 5px;
    font-family: 'Roboto', serif;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    background: #19463D;
    margin-left: 20px;
  }
}