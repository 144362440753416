@import "src/assets/helpers/variables";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 999;
  @include display-flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}
.modalContent {
  margin: auto;
}