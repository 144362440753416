@import "src/assets/helpers/variables";

.slider {
  margin-bottom: 100px;
  padding: 0 20px;
  position: relative;
  .sliderPrevBtn {
    width: 16px;
    height: 33px;
    position: absolute;
    z-index: 200;
    top: 45%;
    left: 4%;
    @media #{$media-ipad} {
      left: 28px;
    }

    .slidePrevBtnIcons {
      width: 16px;
      height: 33px;
      background-size: contain;
      @media #{$media-ipad} {

        width: 50%;
        height: 50%;
      }
    }
  }

  .sliderNextBtn {
    z-index: 200;
    position: absolute;
    top: 45%;
    right: 4%;
    @media #{$media-ipad} {
      right: 28px;

    }
    .sliderNextBtnIcons {
      background-size: contain;
      @media #{$media-ipad} {
        width: 8px;
        height: 16px;
      }
      width: 16px;
      height: 33px;

    }
  }
  .sliderPrevBtn:hover, .sliderNextBtn:hover {
    cursor: pointer;
  }
}


