@import "../../../../../../../assets/helpers/variables";

.componentContainer {
  padding: 0 20px;
}

.componentBackground {
  margin: 0 -20px;
  flex-direction: column;
  background-image: url("../../../../../../../assets/images/background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.titleContainer {
  @include display-flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../../../../assets/images/home-introduce.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 283px;
  height: 70px;

  .title {
    font-size: 23px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #126239;
  }
}

.contentContainer {
  padding: 0 20px;
  width: calc(100% - 40px);
  .gridContainer {
    @media #{$media-ipad} {
      margin-top: 44px;
    }
    margin-left: 20px;
    width: 100%;
    height: auto;
    display: grid;
    gap: 66px 0;
    grid-template-columns: 26px repeat(3, 1fr) 26px;
    .position1 {
      grid-column: 2 / span 1 ;
      grid-row: 1;
    }
    .position2 {
      grid-column: 3 / span 1 ;
      grid-row: 1;
    }
    .position3 {
      grid-column: 4 / span 1 ;
      grid-row: 1;
    }
    .position4 {
      grid-column: 4 / span 1 ;
      grid-row: 2;
    }
    .position5 {
      grid-column: 3 / span 1 ;
      grid-row: 2;
    }
    .position6 {
      grid-column: 2 / span 1 ;
      grid-row: 2;
    }
    .position7 {
      grid-column: 2 / span 1 ;
      grid-row: 3;
    }
    .position8 {
      grid-column: 3 / span 1 ;
      grid-row: 3;
    }
    .rightContainer {
      grid-column: 5 / span 1;
      grid-row: 1 / span 2;
      @include display-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .longArrow {
        position: absolute;
        left: -200%;
        width: 100%;
        height: calc(50% + 33px);
        background-size: contain;
        @media only screen and (max-width : 600px) {
          left: -150%;
        }
        @media only screen and (max-width : 500px) {
          left: -125%;
        }
      }
    }
    .leftContainer {
      grid-column: 1 / span 1;
      grid-row: 2 / span 2;
      @include display-flex;
      justify-content: center;
      align-items: center;
      .longArrow {
        transform: scaleX(-1);
        width: 100%;
        height: calc(50% + 33px);
        background-size: contain;
      }
    }
  }




}


.contentImage {
  width: 100%;
  padding-top: 100%;
}

.grid {
  @include display-flex;
  position: relative;
  .gridContent {
    width: 75%;
    align-items: center;
    @include display-flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    span {
      position: absolute;
      top: 100%;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #126239;
    }
  }

  .arrowContainer {
    width: 25%;
    @include display-flex;
    justify-content: center;
    align-items: center;

    .arrow {
      width: 100%;
      height: auto;
      background-size: contain;
    }
  }
}
.grid:nth-child(4),
.grid:nth-child(5),
.grid:nth-child(9){
  .arrowContainer {
    display: none;
  }
}
.grid:nth-child(n + 6) {
  .arrowContainer {
    transform: scaleX(-1);
  }
}
.grid:nth-child(n + 8) {
  .arrowContainer {
    transform: scaleX(1);
  }
}
.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 58px;
  padding-bottom: 47px;

  .moreBtn {
    height: 40px;
    width: max-content;
    border: 1px solid #005E4B;
    background: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #19463D;
    text-align: center;
    padding: 0 18px;
    cursor: pointer;
  }
}
.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 58px;
  padding-bottom: 47px;
  .moreBtn {
    height: 40px;
    width: max-content;
    border: 1px solid #005E4B;
    background: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #19463D;
    text-align: center;
    padding: 0 18px;
    cursor: pointer;
  }
}